<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              id="filters-store"
              v-model="filter.storeId"
              :required="false"
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="region"
              v-model="filter.region"
              type="vue-select"
              :label="$t('Região')"
              :options="storeRegions()"
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="province"
              v-model="filter.province"
              type="vue-select"
              :label="$t('Estado')"
              :placeholder="$t('Todos')"
              :options="provincesWithStores()"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="city"
              v-model="filter.city"
              type="text"
              :label="$t('Cidade')"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="ean"
              v-model="filter.ean"
              type="text"
              :label="$t('EAN')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="name"
              v-model="filter.name"
              type="text"
              :label="$t('Nome')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="period"
              v-model="filter.period"
              type="date-range-picker"
              :label="$t('Período')"
              opens="left"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="active"
              v-model="filter.active"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              :options="activeInactiveTypes()"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="activeOnline"
              v-model="filter.activeOnline"
              type="vue-select"
              :label="$t('Site?')"
              :placeholder="$t('Todos')"
              :options="yesNoTypes()"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="product-kits-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('PRODUCT_KIT.NAME'))"
        :fields="fields"
        :items="productKits"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(activeOnline)="row">
          <e-status-badge
            :status="row.item.activeOnline"
            type="yesNo"
          />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Update', 'ProductKit')"
            :show-delete="false"
            :show-activate="$can('Activate', 'ProductKit')"
            :show-deactivate="$can('Deactivate', 'ProductKit')"
            @update="onUpdateKit(row)"
            @activate="activateDeactivateKit(row)"
            @deactivate="activateDeactivateKit(row)"
          />
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="product-kit-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('Create', 'ProductKit')"
      :main-tooltip="$t('Adicionar Kit de Produto')"
      @click="onCreateKit"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { province, statusTypes, storeDomains } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'

export default {
  name: 'ProductKitList',
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPageSizeSelector,
    EPaginationSummary,
    fab,
    EFilters,
    EGridActions,
    EStatusBadge,
    EStoreCombo,
  },

  mixins: [statusTypes, province, storeDomains],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/catalog/productKits', {
      productKits: 'productKits',
      paging: 'paging',
      sorting: 'sorting',
      filter: 'filter',
    }),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          tdClass: 'text-right',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('EAN'),
          key: 'ean',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '160px' },
        },
        {
          label: this.$t('Início'),
          key: 'startDate',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
          formatter: value => this.$options.filters.datetime(value, '-'),
          sortable: true,
        },
        {
          label: this.$t('Fim'),
          key: 'endDate',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
          formatter: value => this.$options.filters.datetime(value, '-'),
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Site'),
          key: 'activeOnline',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '50px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/catalog/productKits', [
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'fetchProductKits',
      'resetFilters',
    ]),
    ...mapActions('pages/catalog/productKits/productKitMaintain', {
      stCleanKitMaintain: 'cleanState',
    }),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchProductKits()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onCreateKit() {
      this.stCleanKitMaintain()
      this.$router.push({ name: 'product-kit-add' })
    },

    async onUpdateKit(row) {
      const { id } = row.item
      // TODO: go to new update page
      this.$router.push({ name: 'product-kit-maintain', params: { id } })
    },

    async activateDeactivateKit(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const path = row.item.active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/product-kits/${path}/${row.item.id}`,
            method: 'PUT',
          })
          this.getData()
          this.showSuccess({
            message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
